import goods from "@/service/goods";
import order from "@/service/order";
import merchant from "@/service/merchant";
import statics from "@/service/statics";
import operate from "@/service/operate";

export default {
  merchant,
  order,
  goods,
  statics,
  operate
}
