import layout from '../view/layout'

const router = {
  name: 'statistics',
  path: 'statistics',
  component: layout,
  meta: {
    title: '统计',
    ico: 'el-icon-data-line',
    authkey: 'statistics'
  },
  children: [
    {
      name: 'statistics_order_goods',
      path: 'order_goods',
      component: () => import('../view/statistics/order_goods'),
      meta: {
        route_name: 'statistics_order_goods',
        title: '订货统计',
        ico: '',
        isShow: true,
        authkey: 'statistics_order_goods'
      }
    },
    {
      name: 'statistics_shop',
      path: 'shop',
      component: () => import('../view/statistics/shop'),
      meta: {
        route_name: 'statistics_shop',
        title: '门店统计',
        ico: '',
        isShow: true,
        authkey: 'statistics_shop'
      }
    }
  ]
}

export default router
