import layout from '../view/layout'

const router = {
  name: 'sys',
  path: 'sys',
  component: layout,
  meta: {
    title: '系统',
    ico: 'el-icon-s-tools',
    authkey: 'sys'
  },
  children: [
    {
      name: 'sys_set',
      path: 'set',
      component: () => import('../view/sys/set'),
      meta: {
        route_name: 'sys_set',
        title: '系统设置',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_set'
      }
    },
    {
      name: 'sys_menu',
      path: 'menu',
      component: () => import('../view/sys/menu'),
      meta: {
        route_name: 'sys_menu',
        title: '菜单管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'sys_menu'
      }
    },
    {
      name: 'sys_visitlog',
      path: 'visitlog',
      component: () => import('../view/sys/visitlog'),
      meta: {
        route_name: 'sys_visitlog',
        title: '访问日志',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_visitlog'
      }
    },
    {
      name: 'sys_store',
      path: 'store',
      component: () => import('../view/sys/store'),
      meta: {
        route_name: 'sys_store',
        title: '门店管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_store'
      }
    },
    {
      name: 'sys_store_add',
      path: 'store/add',
      component: () => import('../view/sys/store_add'),
      meta: {
        route_name: 'sys_store',
        title: '添加门店',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'sys_store'
      }
    },
    {
      name: 'sys_supplier',
      path: 'supplier',
      component: () => import('../view/sys/supplier'),
      meta: {
        route_name: 'sys_supplier',
        title: '供应商管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_supplier'
      }
    },
    // {
    //   name: 'sys_car_model',
    //   path: 'carmodel',
    //   component: () => import('../view/sys/cartype'),
    //   meta: {
    //     route_name: 'sys_car_model',
    //     title: '车型管理',
    //     ico: 'el-icon-c-scale-to-original',
    //     isShow: true,
    //     authkey: 'sys_car_model'
    //   }
    // },
    {
      name: 'sys_cartype_add',
      path: 'carmodel/add',
      component: () => import('../view/sys/cartype_add'),
      meta: {
        route_name: 'sys_car_model',
        title: '添加车型',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'sys_car_model'
      }
    },
    {
      name: 'sys_banner',
      path: 'banner',
      component: () => import('../view/sys/banner'),
      meta: {
        route_name: 'sys_banner',
        title: '轮播图',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_banner'
      }
    },
    {
      name: 'sys_banner_add',
      path: 'banner/add',
      component: () => import('../view/sys/banner_add'),
      meta: {
        route_name: 'sys_banner',
        title: '添加轮播图',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'sys_banner'
      }
    },
    {
      name: 'test',
      path: '/test',
      component: () => import('../view/test'),
      meta: { title: '测试页面' }
    }
  ]
}

export default router
