import layout from '../view/layout'

const router = {
  name: 'information',
  path: 'information',
  component: layout,
  meta: {
    title: '信息管理',
    ico: 'el-icon-document',
    authkey: 'information'
  },
  children: [
    {
      name: 'information_staff',
      path: 'staff',
      component: () => import('../view/information/staff'),
      meta: {
        route_name: 'information_staff',
        title: '员工管理',
        ico: '',
        isShow: true,
        authkey: 'information_staff'
      }
    },
    {
      name: 'information_car_model',
      path: 'car/model',
      component: () => import('../view/information/carModel'),
      meta: {
        route_name: 'information_car_model',
        title: '车型管理',
        ico: '',
        isShow: true,
        authkey: 'information_car_model'
      }
    },
    // {
    //   name: 'information_goods_menu',
    //   path: 'goods/menu',
    //   component: () => import('../view/information/goodsMenu'),
    //   meta: {
    //     route_name: 'information_goods_menu',
    //     title: '产品类目',
    //     ico: '',
    //     isShow: true,
    //     authkey: 'information_goods_menu'
    //   }
    // },
    {
      name: 'information_goods',
      path: 'goods',
      component: () => import('../view/information/goods'),
      meta: {
        route_name: 'information_goods',
        title: '产品管理',
        ico: '',
        isShow: true,
        authkey: 'information_goods'
      }
    },
    {
      name: 'information_goods_add',
      path: 'goods/add',
      component: () => import('../view/information/goodsAdd'),
      meta: {
        route_name: 'information_goods',
        title: '产品管理',
        ico: '',
        isShow: false,
        authkey: 'information_goods'
      }
    },
    {
      name: 'information_source',
      path: 'source',
      component: () => import('../view/information/source'),
      meta: {
        route_name: 'information_source',
        title: '客户来源',
        ico: '',
        isShow: true,
        authkey: 'information_source'
      }
    },
    {
      name: 'information_paytype',
      path: 'paytype',
      component: () => import('../view/information/paytype'),
      meta: {
        route_name: 'information_paytype',
        title: '收款方式',
        ico: '',
        isShow: true,
        authkey: 'information_paytype'
      }
    },
    {
      name: 'information_daily_const',
      path: 'daily/const',
      component: () => import('../view/information/dailyConst'),
      meta: {
        route_name: 'information_daily_const',
        title: '每日成本类目',
        ico: '',
        isShow: true,
        authkey: 'information_daily_const'
      }
    }
  ]
}

export default router
