import layout from '../view/layout'

const router = {
  name: 'operate',
  path: 'operate',
  component: layout,
  meta: {
    title: '运营',
    ico: 'el-icon-document',
    authkey: 'operate'
  },
  children: [
    {
      name: 'operate_index',
      path: 'index',
      component: () => import('../view/operate/index'),
      meta: {
        route_name: 'operate_index',
        title: '运营看板',
        ico: '',
        isShow: true,
        authkey: 'operate_index'
      }
    },
    {
      name: 'operate_week_box',
      path: 'week-box',
      component: () => import('../view/operate/week_box'),
      meta: {
        route_name: 'operate_week_box',
        title: '四会制度',
        ico: '',
        isShow: true,
        authkey: 'operate_week'
      }
    },
    {
      name: 'operate_week',
      path: 'week',
      component: () => import('../view/operate/week'),
      meta: {
        route_name: 'operate_week_box',
        title: '四会制度',
        ico: '',
        isShow: false,
        authkey: 'operate_week'
      }
    },
    {
      name: 'operate_week_show',
      path: 'week/show',
      component: () => import('../view/operate/week_show'),
      meta: {
        route_name: 'operate_week_box',
        title: '会议开会',
        ico: '',
        isShow: false,
        authkey: 'operate_week'
      }
    },
    {
      name: 'operate_storefour_box',
      path: 'storefour-box',
      component: () => import('../view/operate/storefour_box'),
      meta: {
        route_name: 'operate_storefour_box',
        title: '店长岗位手册',
        ico: '',
        isShow: true,
        authkey: 'operate_storefour'
      }
    },
    {
      name: 'operate_storefour',
      path: 'storefour',
      component: () => import('../view/operate/storefour'),
      meta: {
        route_name: 'operate_storefour_box',
        title: '店长岗位手册',
        ico: '',
        isShow: false,
        authkey: 'operate_storefour'
      }
    },
    {
      name: 'operate_storefour_show',
      path: 'storefour/show',
      component: () => import('../view/operate/storefour_show'),
      meta: {
        route_name: 'operate_storefour_box',
        title: '店长岗位手册详情',
        ico: '',
        isShow: false,
        authkey: 'operate_storefour'
      }
    },
    {
      name: 'operate_finance_box',
      path: 'finance-box',
      component: () => import('../view/operate/finance_box'),
      meta: {
        route_name: 'operate_finance_box',
        title: '财务登记',
        ico: '',
        isShow: true,
        authkey: 'operate_finance'
      }
    },
    {
      name: 'operate_finance',
      path: 'finance',
      component: () => import('../view/operate/finance'),
      meta: {
        route_name: 'operate_finance_box',
        title: '财务登记表',
        ico: '',
        isShow: false,
        authkey: 'operate_finance'
      }
    },
    {
      name: 'operate_finance_show',
      path: 'finance/show',
      component: () => import('../view/operate/finance_show'),
      meta: {
        route_name: 'operate_finance_box',
        title: '财务登记表',
        ico: '',
        isShow: false,
        authkey: 'operate_finance'
      }
    }
  ]
}

export default router
