import layout from '../view/layout'

const router = {
  name: 'order',
  path: 'order',
  component: layout,
  meta: {
    title: '订单',
    ico: 'el-icon-s-order',
    authkey: 'order'
  },
  children: [
    {
      name: 'order_index',
      path: 'index',
      component: () => import('../view/order/index'),
      meta: {
        route_name: 'order_index',
        title: '订单列表',
        ico: '',
        isShow: true,
        authkey: 'order_trade'
      }
    },
    {
      name: 'order_finance',
      path: 'finance',
      component: () => import('../view/order/finance'),
      meta: {
        route_name: 'order_finance',
        title: '财务确认',
        ico: '',
        isShow: true,
        authkey: 'order_finance'
      }
    },
    {
      name: 'order_send',
      path: 'send',
      component: () => import('../view/order/send'),
      meta: {
        route_name: 'order_send',
        title: '发货管理',
        ico: '',
        isShow: true,
        authkey: 'order_send'
      }
    }
  ]
}

export default router
