import axios from 'axios';
import { router } from '../router/router';
import config from '../config/config.js';
import { getToken } from './auth.js';
import { Message } from 'element-ui'

let { HTTP_REQUEST_URL, HEADER, TOKEN_FORMAT, CACHE_TOKEN, TOKENNAME } = config

// 创建一个 axios 实例
const instance = axios.create({
    baseURL: HTTP_REQUEST_URL, // 公共的接口地址
    timeout: 10000,            // 请求超时时间设置
    withCredentials: true      // 跨域时使用凭证，默认带上cookies
});

// 请求拦截器，config 是发送请求的参数信息
instance.interceptors.request.use(
    config => {
        config.headers['authorization'] = 'Bearer ' + TOKEN_FORMAT(getToken(CACHE_TOKEN));
        return config;
    },
    // 请求拦截的错误回调
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截器，response为响应的结构组织，包括data，headers，status，statusText，config，request
instance.interceptors.response.use(
    response => {
        return response
    },
    // 响应拦截的报错信息，协议报错
    error => {
        if (error && error.response) {
            // console.log(error.response)
        }
        // return Promise.reject(error)
        return error.response
    }
)

// 封装 axios 实例
/**
 * @param {Object} config 同axios配置
 * @param {params} 剩余的其他配置
 * @returns {Axios} 一个 axios 的实列
 */
function request(config) {
    const GET_CONTENT_TYPE = 'application/x-www-form-urlencoded;charset=UTF-8';
    const POST_CONTENT_TYPE = 'application/json;charset=utf-8';
    const method = String.prototype.toUpperCase.call(config.method) || 'GET';
    let headers = config.headers = Object.assign({}, HEADER, config.headers);
    if (!checkKeyIgnoreUpCase(headers, 'content-type')) {
        if (method == 'GET' || method == 'DELETE') headers['Content-Type'] = GET_CONTENT_TYPE;
        if (method === 'POST' || method == 'PUT') headers['Content-Type'] = POST_CONTENT_TYPE;
    }
    if(method == 'GET'){
        return instance(config).then(res => {
            return res;
        }).finally(() => {
        });
    }else if(method == 'POST'){
        return instance(config).then(res => {
            return res;
        }).finally(() => {
        });
    }else{
        return instance(config).finally(() => {
        })
    }
}

/**
 * @description 把上面的request方法转换成一个利于理解，方便的请求方法结构
 * @param {String} url 请求地址，必传值
 * @param {Object} data 数据，默认为空
 * @param {String} method 请求方法，默认GET方法
 * @param {Function} callback 回调函数
 * @param {String} responseType 设置响应文件类型，默认为json，有时候会出现二进制流文件，例如 arraybuffer
 * @returns {Axios} 返回一个axios实例，也就是上面的request方法执行
 */
// 导出一个更简便的方法
function fetch(url, method = "GET", data = {}, callback, responseType = 'json') {
    method = method.toUpperCase();
    const config = {
        url,
        method,
        responseType
    }
    if (method == 'GET') {
        config.params = data;
    } else {
        config.data = data;
    }
    return request(config).then(function (res) {
        if(res.status == 200) {
            var data = res.data
            if(data.errcode == 40001) {
                setTimeout(function () {
                    if(router.history.current.name != 'login') {
                        Message.error('您尚未登录系统，请登录！')
                        window.location.href = '/login'
                    }
                },500)
                return ''
            } else if(data.errcode == 40002) {
                Message.error('您无权访问当前页面！')
                setTimeout(function () {
                    window.location.href = '/'
                },500)
                return ''
            }
            if(typeof callback == "function") {
                callback(data)
            }
        } else {
            Message.error('服务异常，请稍后重试！')
        }
    });
}

// fetch.get()/fetch.post()等等类似这样的页能调用
['options', 'get', 'post', 'put', 'head', 'delete', 'trace', 'connect'].forEach((method) => {
    fetch[method] = (api, data, opt) => fetch(api, method, data, opt || {})
});

// 忽略key大小写检查
function checkKeyIgnoreUpCase(target, key) {
    let res = false;
    if (typeof target !== "object" || target === null) {
        return res;
    }
    let keys = Object.keys(target)
    res = keys.some((k) => {
        if (typeof k === "string") {
            return k.toLocaleLowerCase() === key.toLocaleLowerCase()
        }
        return k === key
    })
    return res;
}
// 导出
export {
    instance,
    request,
    fetch
};
