import { fetch } from '@/utils/request';

// 周会
const weekIndex = (param, callback) => fetch('/admin/1.0/operate/week/index','GET', param, callback);
const weekDetail = (param, callback) => fetch('/admin/1.0/operate/week/detail','GET', param, callback);
const weekAdd = (param, callback) => fetch('/admin/1.0/operate/week/add','POST', param, callback);
const weekFinish = (param, callback) => fetch('/admin/1.0/operate/week/finish','POST', param, callback);
const weekDel = (param, callback) => fetch('/admin/1.0/operate/week/del','POST', param, callback);
const weekCreate = (param, callback) => fetch('/admin/1.0/operate/week/create','POST', param, callback);

// 店长手册
const storefourIndex = (param, callback) => fetch('/admin/1.0/operate/storefour/index','GET', param, callback);
const storefourDetail = (param, callback) => fetch('/admin/1.0/operate/storefour/detail','GET', param, callback);
const storefourAdd = (param, callback) => fetch('/admin/1.0/operate/storefour/add','POST', param, callback);
const storefourFinish = (param, callback) => fetch('/admin/1.0/operate/storefour/finish','POST', param, callback);
const storefourDel = (param, callback) => fetch('/admin/1.0/operate/storefour/del','POST', param, callback);
const storefourCreate = (param, callback) => fetch('/admin/1.0/operate/storefour/create','POST', param, callback);

// 员工
const staffIndex = (param, callback) => fetch('/admin/1.0/operate/staff/index','GET', param, callback);
const staffDetail = (param, callback) => fetch('/admin/1.0/operate/staff/detail','GET', param, callback);
const staffAdd = (param, callback) => fetch('/admin/1.0/operate/staff/add','POST', param, callback);
const staffDel = (param, callback) => fetch('/admin/1.0/operate/staff/del','POST', param, callback);

// 财务
const financeIndex = (param, callback) => fetch('/admin/1.0/operate/finance/index','GET', param, callback);
const financeDetail = (param, callback) => fetch('/admin/1.0/operate/finance/detail','GET', param, callback);
const financeFinish = (param, callback) => fetch('/admin/1.0/operate/finance/finish','POST', param, callback);
const financeDel = (param, callback) => fetch('/admin/1.0/operate/finance/del','POST', param, callback);
const financeCreate = (param, callback) => fetch('/admin/1.0/operate/finance/create','POST', param, callback);
const financeItem = (param, callback) => fetch('/admin/1.0/operate/finance/item','POST', param, callback);

// 产品类目
const goodsMenuIndex = (param, callback) => fetch('/admin/1.0/operate/goods/menu/index','GET', param, callback);
const goodsMenuDetail = (param, callback) => fetch('/admin/1.0/operate/goods/menu/detail','GET', param, callback);
const goodsMenuAdd = (param, callback) => fetch('/admin/1.0/operate/goods/menu/add','POST', param, callback);
const goodsMenuDel = (param, callback) => fetch('/admin/1.0/operate/goods/menu/del','POST', param, callback);

// 车型
const carModelIndex = (param, callback) => fetch('/admin/1.0/operate/car/mode/index','GET', param, callback);
const carModelDetail = (param, callback) => fetch('/admin/1.0/operate/car/mode/detail','GET', param, callback);
const carModelAdd = (param, callback) => fetch('/admin/1.0/operate/car/mode/add','POST', param, callback);
const carModelDel = (param, callback) => fetch('/admin/1.0/operate/car/mode/del','POST', param, callback);

// 产品
const goodsIndex = (param, callback) => fetch('/admin/1.0/operate/goods/index','GET', param, callback);
const goodsTree = (param, callback) => fetch('/admin/1.0/operate/goods/tree','GET', param, callback);
const goodsDetail = (param, callback) => fetch('/admin/1.0/operate/goods/detail','GET', param, callback);
const goodsAdd = (param, callback) => fetch('/admin/1.0/operate/goods/add','POST', param, callback);
const goodsDel = (param, callback) => fetch('/admin/1.0/operate/goods/del','POST', param, callback);

// 车型
const sourceIndex = (param, callback) => fetch('/admin/1.0/operate/source/index','GET', param, callback);
const sourceDetail = (param, callback) => fetch('/admin/1.0/operate/source/detail','GET', param, callback);
const sourceAdd = (param, callback) => fetch('/admin/1.0/operate/source/add','POST', param, callback);
const sourceDel = (param, callback) => fetch('/admin/1.0/operate/source/del','POST', param, callback);

// 收款方式
const paytypeIndex = (param, callback) => fetch('/admin/1.0/operate/paytype/index','GET', param, callback);
const paytypeDetail = (param, callback) => fetch('/admin/1.0/operate/paytype/detail','GET', param, callback);
const paytypeAdd = (param, callback) => fetch('/admin/1.0/operate/paytype/add','POST', param, callback);
const paytypeDel = (param, callback) => fetch('/admin/1.0/operate/paytype/del','POST', param, callback);

// 每日成本类目
const dailyCostDetail = (param, callback) => fetch('/admin/1.0/operate/set/daily/const/detail','GET', param, callback);
const dailyCostAdd = (param, callback) => fetch('/admin/1.0/operate/set/daily/const/add','POST', param, callback);

// 统计
const staticToday = (param, callback) => fetch('/admin/1.0/operate/static/today','POST', param, callback);
const staticGoods = (param, callback) => fetch('/admin/1.0/operate/static/goods','POST', param, callback);
const staticStaff = (param, callback) => fetch('/admin/1.0/operate/static/staff','POST', param, callback);
const staticProfit = (param, callback) => fetch('/admin/1.0/operate/static/profit','POST', param, callback);

export default {
  staticToday,
  staticGoods,
  staticStaff,
  staticProfit,

  dailyCostDetail,
  dailyCostAdd,

  sourceIndex,
  sourceDetail,
  sourceAdd,
  sourceDel,

  paytypeIndex,
  paytypeDetail,
  paytypeAdd,
  paytypeDel,

  goodsIndex,
  goodsTree,
  goodsDetail,
  goodsAdd,
  goodsDel,

  carModelIndex,
  carModelDetail,
  carModelAdd,
  carModelDel,

  goodsMenuIndex,
  goodsMenuDetail,
  goodsMenuAdd,
  goodsMenuDel,

  financeIndex,
  financeDetail,
  financeFinish,
  financeDel,
  financeCreate,
  financeItem,

  weekIndex,
  weekDetail,
  weekAdd,
  weekFinish,
  weekDel,
  weekCreate,
  storefourIndex,
  storefourDetail,
  storefourAdd,
  storefourFinish,
  storefourDel,
  storefourCreate,
  staffIndex,
  staffDetail,
  staffAdd,
  staffDel
}
