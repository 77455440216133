import layout from '../view/layout'

const router = {
  name: 'goods',
  path: 'goods',
  component: layout,
  meta: {
    title: '商品',
    ico: 'el-icon-goods',
    authkey: 'goods'
  },
  children: [
    {
      name: 'goods_menu',
      path: 'menu',
      component: () => import('../view/goods/menu'),
      meta: {
        route_name: 'goods_menu',
        title: '分类管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'goods_menu'
      }
    },
    {
      name: 'goods_specs_template',
      path: 'specs/template',
      component: () => import('../view/goods/specs_tempalte'),
      meta: {
        route_name: 'goods_specs_template',
        title: '规格模板',
        ico: '',
        isShow: true,
        authkey: 'goods_specs_template'
      }
    },
    {
      name: 'goods_index',
      path: 'index',
      component: () => import('../view/goods/index'),
      meta: {
        route_name: 'goods_index',
        title: '商品列表',
        ico: '',
        isShow: true,
        authkey: 'goods_index'
      }
    },
    {
      name: 'goods_add',
      path: 'add',
      component: () => import('../view/goods/add'),
      meta: {
        route_name: 'goods_index',
        title: '商品添加',
        ico: '',
        isShow: false,
        authkey: 'goods_index'
      }
    },
    {
      name: 'goods_evaluate',
      path: 'evaluate',
      component: () => import('../view/goods/evaluate'),
      meta: {
        route_name: 'goods_evaluate',
        title: '商品评价',
        ico: '',
        isShow: true,
        authkey: 'goods_evaluate'
      }
    }
  ]
}

export default router
