<template>
  <div class="layout">
    <div class="tab-layout">
      <div class="logo">
        <img src="../assets/images/logo2.png" alt="">
      </div>
      <el-menu
        :default-active="default_active"
        background-color="#282c34"
        text-color="#fff"
        active-text-color="#409EFF"
        class="tab-menu">
        <el-submenu v-for="(item, index) in parent" :key="index" :index="index + ''">
          <template slot="title"><i :class="item.meta.ico"></i>{{ item.meta.title }}</template>
          <template v-for="(child, cindex) in item.children">
            <el-menu-item :index="child.name" :key="cindex" v-if="child.meta.isShow" @click="changeTwo(index, cindex)">
              <span slot="title">{{ child.meta.title }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
      </el-menu>
    </div>
    <div class="top-layout">
      <div class="nav">
        <span class="menu">
          <i class="el-icon-s-fold showico"></i>
          <!--i class="el-icon-s-unfold showico"></i-->
          <span v-for="(item, index) in bread_crumb" :key="item.id">
            <span role="presentation" class="el-breadcrumb__separator" v-if="index>0">&gt;</span>
            <span><i :class="item.meta.ico"></i><span>{{ item.meta.title }}</span></span>
          </span>
        </span>
        <span class="uhead">
          <el-avatar size="medium" :src="circleUrl" class="img"></el-avatar>
          <el-dropdown @command="handleDrop">
            <span class="el-dropdown-link pt" style="color: #ccc;">
              个人中心<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-user" command="myinfo">我的信息</el-dropdown-item>
              <el-dropdown-item icon="el-icon-switch-button" command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </div>
    </div>
    <div class="body-layout">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>

import { removeToken } from '../utils/auth'
export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      circleUrl: "",
      privs: [],
      parent: [],
      bread_crumb: [],
      default_active: ''
    };
  },
  created() {
    this.default_active = this.$route.meta.route_name
    this.getMyPrivs();
  },
  mounted() {
  },
  computed: {
  },
  watch:{
    $route(to,from){
      // console.log(from.path) // 从哪来
      // console.log(to.path) // 到哪去
      // console.log(this.$route)
    }
  },
  methods: {
    rsyncRouter() {
      var that = this
      var check_parent = that.$route.matched[2].parent
      var check_child = that.$route.matched[2]
      var parentRouter = this.$router.options.routes[2].children
      parentRouter = parentRouter.filter(function (item, index) {
        if(item.meta.authkey && !that.in_array(item.meta.authkey,that.privs)) {
          return false
        }
        item.children = item.children.filter(function (item2, index2) {
          if(item2.meta.authkey && !that.in_array(item2.meta.authkey,that.privs)) {
            return false
          } else {
            return item2.meta.isShow
          }
        })
        return true
      })
      this.parent = parentRouter
      this.bread_crumb[0] = check_parent
      this.bread_crumb[1] = check_child
    },
    changeTwo(index, cindex) {
      if (this.$route.name != this.parent[index].children[cindex].name) {
        this.$router.push({name: this.parent[index].children[cindex].name})
        this.bread_crumb[0] = this.parent[index]
        this.bread_crumb[1] = this.parent[index].children[cindex]
      }
    },
    getMyPrivs() {
      var that = this
      this.$api.merchant.myPrivs(function (res) {
        if(res.errcode == 0) {
          that.privs = res.data
          that.rsyncRouter()
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleDrop(command) {
      var that = this
      if(command == 'logout') {
        this.$api.merchant.authLogout(function (res) {
          if(res.errcode == 0) {
            removeToken()
            that.success(res.errmsg)
            that.$router.push({name: 'login'})
          } else {
            that.fail(res.errmsg)
          }
        })
      } else if(command == 'myinfo') {
        that.$router.push({name: 'person_index'})
      }
    },
    in_array(search,arr) {
      for(var i in arr){
        if(arr[i]==search){
          return true;
        }
      }
      return false;
    }
  }
};
</script>
<style scoped>
  .layout {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .tab-layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 100%;
    overflow: hidden;
    background: #282c34;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  }
  .tab-layout * {
    transition: all .3s cubic-bezier(.645,.045,.355,1),border 0s,color .1s,font-size 0s;
  }
  .tab-layout .logo {
    text-align: left;
    background-color: #282c34;
    height: 59px;
    border-bottom: 1px solid #3a3a3a;
  }
  .tab-layout .logo img {
    width: 50%;
    margin-left: 27px;
  }
  .tab-menu {
    width: 207px;
    height: 90%;
    overflow-y: scroll;
  }
  .tab-menu li i {
    margin-top: -5px;
  }
  .tab-logo {
    font-size: 28px;
    padding: 15px 0 15px 0;
  }
  .tab-one {
    background-color: #282c34;
    width: 64px;
    height: 100%;
    text-align: center;
    color: #fff;
  }
  .tab-one li {
    width: 54px;
    height: 54px;
    border-radius: 4px;
    margin-left: 5px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .tab-one li i {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 2px;
  }
  .tab-one li .txt {
    font-size: 13px;
    font-weight: 500;
  }
  .tab-one li.active {
    background-color: #1890ff;
  }
  .tab-two {
    position: fixed;
    top: 0;
    left: 74px;
    width: 182px;
    text-align: center;
  }
  .tab-two .title {
    color: #515a6e;
    display: inline-block;
    overflow: hidden;
    font-size: 20px;
    line-height: 55px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
  .tab-two .divider {
    display: block;
    height: 1px;
    width: 100%;
    color: #515a6e;
    border-top: 1px solid #f6f6f6;
    margin-top: 10px;
  }
  .tab-two .divider_txt {
    font-size: 14px;
    font-weight: 500;
    background-color: #fff;
    margin-top: -12px;
    display: inline-block;
    position: absolute;
    padding: 0 15px;
    left: 60px;
  }
  .tab-two ul {
    margin-top: 30px;
  }
  .tab-two li {
    text-align: left;
    height: 50px;
    overflow: hidden;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 14px;
    cursor: pointer;
    transition: border-color .3s,background-color .3s,color .3s;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 20px;
  }
  .tab-two li i {
    margin-right: 6px;
  }
  .tab-two li.active {
    color: #1890ff;
    background-color: #e8f4ff;
  }
  .tab-two li:hover {
    color: #1890ff;
    background-color: #e8f4ff;
  }
  .top-layout {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    left: 200px;
    width: calc(100% - 200px);
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    padding: 0 15px;
    background-color: #282c34;
  }
  .top-layout .nav {
    position: relative;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
  }
  .top-layout .nav .menu {
    color: #ccc;
    font-size: 13px;
  }
  .top-layout .nav .menu i {
    margin-right: 4px;
  }
  .top-layout .showico {
    font-size: 16px;
    margin-right: 15px !important;
    cursor: pointer;
  }
  .top-layout .tab {
    height: 50px;
  }
  .top-layout .nav .uhead {
    float: right;
    margin-right: 10px;
    color: #ccc;
  }
  .top-layout .nav .uhead .img {
    vertical-align: middle;
    margin-right: 10px;
    margin-top: -5px;
  }
  .top-layout .tab li {
    float: left;
    border: 1px solid #dcdfe6;
    height: 30px;
    line-height: 30px;
    margin: 10px 10px 0 0;
    padding: 0 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  .top-layout .tab li:hover {
    color: #1890ff;
    background: #e8f4ff;
    border: 1px solid #1890ff;
    transition: border-color .3s,background-color .3s,color .3s;
  }
  .top-layout .tab li:hover .close {
    display: inline-block !important;
  }
  .body-layout {
    box-sizing: border-box;
    padding: 70px 10px 0 205px;
  }
</style>
