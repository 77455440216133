import { Loading } from 'element-ui'

var loadingObj;

// 轻提示
function tips(msg) {
  this.$message({
    showClose: true,
    message: msg
  });
}

// 加载中
function showLoading(msg) {
  var options = {
    text: '加载中..',
    background: 'rgba(0, 0, 0, 0.7)'
  }
  loadingObj = Loading.service(options);
}

// 加载中
function hideLoading() {
  if(loadingObj) {
    loadingObj.close()
    loadingObj = ''
  }
}

// 成功
function success(msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  });
}

// 失败
function fail(msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error'
  });
}

// 返回
function reBack() {
  // this.$router.go(-1)
  window.history.go(-1)
}

// 判断是否为空
function isEmpty(obj) {
  if(!obj && obj !== 0 && obj !== '') {
    return true;
  }
  if(Array.prototype.isPrototypeOf(obj) && obj.length === 0) {
    return true;
  }
  if(Object.prototype.isPrototypeOf(obj) && Object.keys(obj).length === 0) {
    return true;
  }
  return false;
}

function formatDate(date) {
  if(!date) {
    return '';
  }
  if(typeof date == 'string') {
    return date
  }
  var year = date.getFullYear()
  var month = date.getMonth()+1
  var day = date.getDate()
  return year + '-' + (month>9 ? month : '0' + month) + '-' + (day>9 ? day : '0' + day)
}

function formatTime(date) {
  if(!date) {
    return '';
  }
  if(typeof date == 'string') {
    return date
  }
  var year = date.getFullYear()
  var month = date.getMonth()+1
  var day = date.getDate()
  var hour = date.getHours()
  var min = date.getMinutes()
  var second = date.getSeconds()
  return year + '-' + (month>9 ? month : '0' + month) + '-' + (day>9 ? day : '0' + day) + ' ' + (hour>9 ? hour : '0' + hour) + ':' + (min>9 ? min : '0' + min) + ':' + (second>9 ? second : '0' + second)
}

export default {
  tips,
  showLoading,
  hideLoading,
  success,
  fail,
  reBack,
  isEmpty,
  formatDate,
  formatTime
}
