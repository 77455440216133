<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    computed: {

    },
    created () {

    },
    methods: {

    }
  }
</script>

<style scoped>

</style>
