<template>
  <div class="box">
    404
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
  }
};
</script>
<style scoped>
  .box {
    font-size: 50px;
    text-align: center;
    padding-top: 100px;
  }
</style>
