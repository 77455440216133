import { fetch } from '@/utils/request';

// 统计
const staticsOrderGoods = (param, callback) => fetch('/admin/1.0/statics/order/goods','GET', param, callback); //进货统计
const staticsMonth = (param, callback) => fetch('/admin/1.0/statics/order/month','GET', param, callback); //月份数据
const staticsDay = (param, callback) => fetch('/admin/1.0/statics/order/day','GET', param, callback); //天份数据

const staticsOrderIndex = (param, callback) => fetch('/admin/1.0/statics/order/index','GET', param, callback); //订货统计
const staticsOrderLog = (param, callback) => fetch('/admin/1.0/statics/order/log','GET', param, callback); //订货明细列表

const staticsGoOver = (param, callback) => fetch('/admin/1.0/statics/order/goover','GET', param, callback); //对比过去

const staticsOrderProgress = (param, callback) => fetch('/admin/1.0/statics/order/progress','GET', param, callback); //未来进度-列表
const staticsOrderProgressEdit = (param, callback) => fetch('/admin/1.0/statics/order/progress/edit','POST', param, callback); //未来进度-编辑
const staticsOrderProgressDel = (param, callback) => fetch('/admin/1.0/statics/order/progress/del','POST', param, callback); //未来进度-删除

const staticsOrderSummary = (param, callback) => fetch('/admin/1.0/statics/order/summary','GET', param, callback); //总部汇总

export default {
  staticsOrderGoods,
  staticsMonth,
  staticsDay,
  staticsOrderIndex,
  staticsOrderLog,
  staticsGoOver,
  staticsOrderProgress,
  staticsOrderProgressEdit,
  staticsOrderProgressDel,
  staticsOrderSummary
}
