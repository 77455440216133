import { fetch } from '@/utils/request';

// 订单
const orderIndex = (param, callback) => fetch('/admin/1.0/order/index','GET', param, callback);
const orderCancel = (param, callback) => fetch('/admin/1.0/order/cancel','POST', param, callback);
const orderDelivery = (param, callback) => fetch('/admin/1.0/order/delivery','POST', param, callback);
const orderSurepay = (param, callback) => fetch('/admin/1.0/order/surepay','POST', param, callback);
const orderSetump = (param, callback) => fetch('/admin/1.0/order/setump','POST', param, callback);
const orderInvoice = (param, callback) => fetch('/admin/1.0/order/invoice','POST', param, callback);
const orderShipmentsLog = (param, callback) => fetch('/admin/1.0/order/shipments/log','GET', param, callback);

export default {
  orderIndex,
  orderCancel,
  orderDelivery,
  orderSurepay,
  orderSetump,
  orderInvoice,
  orderShipmentsLog
}
