import { fetch } from '@/utils/request';

// 基础
const cityTree = (callback) => fetch('/admin/1.0/common/getCityTree','GET',{}, callback);
const authLogin = (param, callback) => fetch('/admin/1.0/auth/login','POST',param, callback);
const authLogout = (callback) => fetch('/admin/1.0/auth/logout','POST',false, callback);
const myIndex = (callback) => fetch('/admin/1.0/my/index','GET',{}, callback);
const myUpdate = (param, callback) => fetch('/admin/1.0/my/update','POST',param, callback);
const myPrivs = (callback) => fetch('/admin/1.0/my/privs','GET',{}, callback);
const myUpdatePwd = (param, callback) => fetch('/admin/1.0/my/resetpwd','POST',param, callback);
const sysSet = (callback) => fetch('/admin/1.0/sys/set','GET',{}, callback);

// 文件
const FileUpload = (param, callback) => fetch('/admin/1.0/common/uploadimg','POST',param, callback);
const fileList = (param, callback) => fetch('/admin/1.0/common/filelist','GET',param, callback);
const filedel = (param, callback) => fetch('/admin/1.0/common/filedel','POST',param, callback);
const filesign = (param, callback) => fetch('/admin/1.0/common/filesign','POST',param, callback);

// 系统
const sysDetail = (callback) => fetch('/admin/1.0/sys/info','GET', {}, callback);
const sysEdit = (param, callback) => fetch('/admin/1.0/sys/update','POST', param, callback);

// 用户
const userIndex = (param, callback) => fetch('/admin/1.0/user/index','GET', param, callback);
const userDetail = (param, callback) => fetch('/admin/1.0/user/detail','GET', param, callback);
const userAdd = (param, callback) => fetch('/admin/1.0/user/add','POST', param, callback);
const userEdit = (param, callback) => fetch('/admin/1.0/user/edit','POST', param, callback);
const userDelete = (param, callback) => fetch('/admin/1.0/user/del','POST', param, callback);
const userShenhe = (param, callback) => fetch('/admin/1.0/user/shenhe','POST', param, callback);
const userVisitLog = (param, callback) => fetch('/admin/1.0/user/visitlog','GET', param, callback);

// 群组
const groupIndex = (param, callback) => fetch('/admin/1.0/group/index','GET', param, callback);
const groupDetail = (param, callback) => fetch('/admin/1.0/group/detail','GET', param, callback);
const groupAdd = (param, callback) => fetch('/admin/1.0/group/add','POST', param, callback);
const groupEdit = (param, callback) => fetch('/admin/1.0/group/edit','POST', param, callback);
const groupDelete = (param, callback) => fetch('/admin/1.0/group/del','POST', param, callback);

// 菜单
const menuIndex = (param, callback) => fetch('/admin/1.0/menu/index','GET', param, callback);
const menuDetail = (param, callback) => fetch('/admin/1.0/menu/detail','GET', param, callback);
const menuAdd = (param, callback) => fetch('/admin/1.0/menu/add','POST', param, callback);
const menuEdit = (param, callback) => fetch('/admin/1.0/menu/edit','POST', param, callback);
const menuDelete = (param, callback) => fetch('/admin/1.0/menu/del','POST', param, callback);

// 链接
const linkIndex = (param, callback) => fetch('/admin/1.0/link/index','GET', param, callback);
const linkInfo = (param, callback) => fetch('/admin/1.0/link/detail','GET', param, callback);
const linkEdit = (param, callback) => fetch('/admin/1.0/link/add','POST', param, callback);
const linkDel = (param, callback) => fetch('/admin/1.0/link/del','POST', param, callback);
const linkChangeStatus = (param, callback) => fetch('/admin/1.0/link/changeStatus','POST', param, callback);

// 门店
const storeIndex = (param, callback) => fetch('/admin/1.0/store/index','GET', param, callback);
const storeDetail = (param, callback) => fetch('/admin/1.0/store/detail','GET', param, callback);
const storeAdd = (param, callback) => fetch('/admin/1.0/store/add','POST', param, callback);
const storeEdit = (param, callback) => fetch('/admin/1.0/store/edit','POST', param, callback);
const storeDelete = (param, callback) => fetch('/admin/1.0/store/del','POST', param, callback);
const balanceEdit = (param, callback) => fetch('/admin/1.0/store/balance/edit','POST', param, callback);
const storeChangeType = (param, callback) => fetch('/admin/1.0/store/changeType','POST', param, callback);

// 轮播图
const bannerIndex = (param, callback) => fetch('/admin/1.0/banner/index','GET', param, callback);
const bannerDetail = (param, callback) => fetch('/admin/1.0/banner/detail','GET', param, callback);
const bannerAdd = (param, callback) => fetch('/admin/1.0/banner/add','POST', param, callback);
const bannerEdit = (param, callback) => fetch('/admin/1.0/banner/edit','POST', param, callback);
const bannerDelete = (param, callback) => fetch('/admin/1.0/banner/del','POST', param, callback);

// 车型
const cartypeIndex = (param, callback) => fetch('/admin/1.0/carmodel/index','GET', param, callback);
const cartypeDetail = (param, callback) => fetch('/admin/1.0/carmodel/detail','GET', param, callback);
const cartypeAdd = (param, callback) => fetch('/admin/1.0/carmodel/add','POST', param, callback);
const cartypeEdit = (param, callback) => fetch('/admin/1.0/carmodel/edit','POST', param, callback);
const cartypeDelete = (param, callback) => fetch('/admin/1.0/carmodel/del','POST', param, callback);

// 供应商
const supplierIndex = (param, callback) => fetch('/admin/1.0/supplier/index','GET', param, callback);
const supplierDetail = (param, callback) => fetch('/admin/1.0/supplier/detail','GET', param, callback);
const supplierAdd = (param, callback) => fetch('/admin/1.0/supplier/add','POST', param, callback);
const supplierEdit = (param, callback) => fetch('/admin/1.0/supplier/edit','POST', param, callback);
const supplierDelete = (param, callback) => fetch('/admin/1.0/supplier/del','POST', param, callback);

export default {
  cityTree,
  authLogin,
  authLogout,
  myIndex,
  myUpdate,
  myPrivs,
  myUpdatePwd,
  sysSet,
  FileUpload,
  fileList,
  filedel,
  filesign,
  sysDetail,
  sysEdit,
  userIndex,
  userDetail,
  userAdd,
  userEdit,
  userDelete,
  userShenhe,
  userVisitLog,
  groupIndex,
  groupDetail,
  groupAdd,
  groupEdit,
  groupDelete,
  menuIndex,
  menuDetail,
  menuAdd,
  menuEdit,
  menuDelete,
  linkIndex,
  linkInfo,
  linkEdit,
  linkDel,
  linkChangeStatus,
  storeIndex,
  storeDetail,
  storeAdd,
  storeEdit,
  storeDelete,
  balanceEdit,
  storeChangeType,
  bannerIndex,
  bannerDetail,
  bannerAdd,
  bannerEdit,
  bannerDelete,
  cartypeIndex,
  cartypeDetail,
  cartypeAdd,
  cartypeEdit,
  cartypeDelete,
  supplierIndex,
  supplierDetail,
  supplierAdd,
  supplierEdit,
  supplierDelete
}
