import { fetch } from '@/utils/request';

// 商品分类
const menuIndex = (param, callback) => fetch('/admin/1.0/goods/menu/index','GET', param, callback);
const menuList = (param, callback) => fetch('/admin/1.0/goods/menu/menu','GET', param, callback);
const menuDetail = (param, callback) => fetch('/admin/1.0/goods/menu/detail','GET', param, callback);
const menuAll = (param, callback) => fetch('/admin/1.0/goods/menu/list','GET', param, callback);
const menuAdd = (param, callback) => fetch('/admin/1.0/goods/menu/add','POST', param, callback);
const menuEdit = (param, callback) => fetch('/admin/1.0/goods/menu/edit','POST', param, callback);
const menuDelete = (param, callback) => fetch('/admin/1.0/goods/menu/del','POST', param, callback);

// 商品规格模板
const specsTemplateIndex = (param, callback) => fetch('/admin/1.0/goods/specsTemplate/index','GET', param, callback);
const specsTemplateInfo = (param, callback) => fetch('/admin/1.0/goods/specsTemplate/detail','GET', param, callback);
const specsTemplateAdd = (param, callback) => fetch('/admin/1.0/goods/specsTemplate/add','POST', param, callback);
const specsTemplateEdit = (param, callback) => fetch('/admin/1.0/goods/specsTemplate/edit','POST', param, callback);
const specsTemplateDel = (param, callback) => fetch('/admin/1.0/goods/specsTemplate/del','POST', param, callback);

// 商品
const goodsIndex = (param, callback) => fetch('/admin/1.0/goods/index','GET', param, callback);
const goodsInfo = (param, callback) => fetch('/admin/1.0/goods/detail','GET', param, callback);
const goodsAdd = (param, callback) => fetch('/admin/1.0/goods/add','POST', param, callback);
const goodsEdit = (param, callback) => fetch('/admin/1.0/goods/edit','POST', param, callback);
const goodsDel = (param, callback) => fetch('/admin/1.0/goods/del','POST', param, callback);
const goodsShenhe = (param, callback) => fetch('/admin/1.0/goods/shenhe','POST', param, callback);
const goodsRec = (param, callback) => fetch('/admin/1.0/goods/rec','POST', param, callback);

// 商品评价
const evaluateIndex = (param, callback) => fetch('/admin/1.0/evaluate/index','GET', param, callback);
const evaluateDel = (param, callback) => fetch('/admin/1.0/evaluate/del','POST', param, callback);

export default {
  menuIndex,
  menuList,
  menuDetail,
  menuAll,
  menuAdd,
  menuEdit,
  menuDelete,
  specsTemplateIndex,
  specsTemplateInfo,
  specsTemplateAdd,
  specsTemplateEdit,
  specsTemplateDel,
  goodsIndex,
  goodsInfo,
  goodsAdd,
  goodsEdit,
  goodsDel,
  goodsShenhe,
  goodsRec,
  evaluateIndex,
  evaluateDel
}
